import React from "react"
import {
  PLIContainer,
  PLITitle,
  PLIDesc,
  PLILink,
  PLITags,
  PLIImage,
  PLITextContainer,
  PLIImageContainer,
  PLITagsContainer,
} from "./ProjectListItemStyles"
import { coverColor, linkDuration } from "../../../static/AnilinkConstants/AnilinkConstants"

const ProjectListItem = props => {
  return (
    <PLIContainer>
      <PLITextContainer>
        <PLITitle>{props.title.text}</PLITitle>
        <PLIDesc>{props.description.text}</PLIDesc>
        <PLILink duration={linkDuration} to={props.link.url} cover bg={coverColor} direction="right">
          &gt; Read more about{" "}
          {props.link.uid.charAt(0).toUpperCase() + props.link.uid.slice(1)}
        </PLILink>
        <PLITagsContainer>
          {props.tags.map((element, index) => {
            return <PLITags key={index}>{element.project_tags.text}</PLITags>
          })}
        </PLITagsContainer>
      </PLITextContainer>

      <PLIImageContainer>
        <PLIImage src={props.image.url} />
      </PLIImageContainer>
    </PLIContainer>
  )
}

export default ProjectListItem
