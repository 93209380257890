import styled from "styled-components"
import { mch1, primaryTextColor } from "../../../static/type/Typography"
import { device } from "../../../static/sizes/Sizes"

export const HeroContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 160px;

  @media ${device.tablet} {
    margin-top: 60px;
    margin-bottom: 180px;
  }
`

export const HeroTitle = styled(mch1)`
  font-size: 100px;
  line-height: normal;
  text-align: center;
  color: #575757;
  font-weight: 100;
  margin: 0;

  @media ${device.tablet} {
    font-size: 50px;
  }
`

export const HeroDescription = styled.div`
  padding: 20px 0 30px;
  font-size: 20px;
  text-align: center;
  color: #a7a7a7;
  font-family: "Roboto", sans-serif;
`

export const HeroSocialContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 360px;
  margin: auto;
`

export const HeroSocialItem = styled.li`
  list-style: none;
`

export const HeroSocialLinkWrapper = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  font-size: 14px;
  color: ${primaryTextColor};

  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;

  &:hover {
    color: #1c6fff;
    text-decoration: underline;
  }

  &:hover img {
    filter: invert(0.4) sepia(0.8) saturate(5) hue-rotate(185deg);
  }
`

export const HeroSocialItemLogo = styled.img`
  width: 20px;
  height: 30px;
  margin: auto;
`
