import styled from "styled-components"
import { mch2, mcp, mcInternalLink } from "../../../static/type/Typography"
import { device } from "../../../static/sizes/Sizes"

export const PLIContainer = styled.div`
  min-width: 1000px;
  padding: 30px 60px;
  margin: 0 60px 160px;
  min-height: 350px;
  border-radius: 10px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${device.laptopL} {
    min-width: 600px;
    padding: 20px 40px;
    margin: 0 40px 120px;
    min-height: 310px;
  }

  @media ${device.laptopS} {
    margin: auto;
    min-width: 300px;
    padding: 20px 40px 40px;
    margin: 0 60px 160px;
    border-radius: 10px;
    flex-direction: column-reverse;
  }

  @media ${device.tablet} {
    margin: 0 0 160px;
  }
`
export const PLITextContainer = styled.div`
  margin: 0 20px;
  width: 40%;

  @media ${device.laptopL} {
    width: 35%;
  }

  @media ${device.laptopS} {
    width: 65%;
  }

  @media ${device.tablet} {
    width: 90%;
}
`

export const PLITitle = styled(mch2)`
  font-size: 24px;
  font-weight: 800;

  @media ${device.laptopL} {
    font-size: 20px;
  }
`

export const PLIDesc = styled(mcp)`
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 10px;
  @media ${device.laptopL} {
    font-size: 18px;
  }
`

export const PLILink = styled(mcInternalLink)`
  padding-top: 30px;
  text-decoration: none;
  padding-bottom: 5px;
  padding-right: 5px;
  box-shadow: inset 0 -4px 0 0 #eaf8ff;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  font-size: 18px;

  &:hover {
    padding-bottom: 10px;
    box-shadow: inset 0 -6px 0 0 #1c6fff;
  }
`
export const PLITagsContainer = styled.div`
  padding-top: 10px;
`

export const PLITags = styled.div`
  font-size: 14px;
  line-height: 14px;
  background-color: #f9f9f9;
  display: inline-block;
  border-radius: 40px;
  padding: 10px 20px;
  margin-right: 15px;
  margin-top: 20px;
`

export const PLIImageContainer = styled.div`
  width: 60%;
  margin: -140px 0 0;

  @media ${device.laptopL} {
    width: 65%;
  }

  @media ${device.tablet} {
      width: 90%;
  }
`

export const PLIImage = styled.img`
  display: block;
  width: 100%;
  margin: auto;
`
