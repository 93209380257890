import React from "react"
import ProjectListItem from "./ProjectListItem"

const ProjectList = props => {
  return (
    <div>
      {props.projects.map((element, index) => {
        return (
          <ProjectListItem
            key={index}
            title={element.primary.project_title}
            description={element.primary.project_description}
            image={element.primary.project_image}
            link={element.primary.project_link}
            tags={element.items}
          />
        )
      })}
    </div>
  )
}

export default ProjectList
