import React from "react"
import {
  BottomProjectsContainer,
  BottomProject,
  BottomProjectImagesContainer,
  BottomProjectsTitle,
} from "./BottomProjectStyles"

const BottomProjects = props => {
  return (
    <BottomProjectsContainer>
      <BottomProjectsTitle>Other projects include:</BottomProjectsTitle>
      <BottomProjectImagesContainer>
        {props.projectImages.map((element, index) => {
          return (
            <BottomProject
              key={index}
              src={element.other_work_image.url}
              alt={element.other_work_image.alt}
            />
          )
        })}
      </BottomProjectImagesContainer>
    </BottomProjectsContainer>
  )
}

export default BottomProjects
