import React from "react"
// import Hero from "../components/homepage/hero/HomepageHero"
import ProjectList from "../components/Homepage/ProjectList/ProjectList"
import BottomProjects from "../components/Homepage/BottomProjects/BottomProjects"
import { graphql } from "gatsby"
import { HomepageContainer } from "../templates/indexStyles"
import Hero from "../components/Homepage/Hero/HomepageHero"
import SEO from "../components/seo"
import "typeface-pt-sans";
import "typeface-roboto";

const Home = ({ data: { prismicHomepage } }) => {
  const { data } = prismicHomepage
  return (
    <HomepageContainer>
      <SEO />
      <Hero />
      <ProjectList projects={data.body} />
      <BottomProjects projectImages={data.other_work} />
    </HomepageContainer>
  )
}

export const pageQuery = graphql`
  query Homepage {
    prismicHomepage {
      id
      data {
        other_work {
          other_work_image {
            url
            alt
          }
        }
        body {
          ... on PrismicHomepageBodyHomepageWork {
            id
            items {
              project_tags {
                text
              }
            }
            primary {
              project_description {
                text
              }
              project_image {
                alt
                url
              }
              project_link {
                uid
                url
              }
              project_title {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default Home
