import React from "react"
import {
  HeroTitle,
  HeroDescription,
  HeroContainer,
  HeroSocialContainer,
  HeroSocialItem,
  HeroSocialLinkWrapper,
  HeroSocialItemLogo,
} from "./HomepageHeroStyles"

const Hero = props => {
  const social = [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/melvin-c-chen/",
      icon: require("../../../static/Homepage_Social/linkedin-logo.svg"),
    },
    {
      name: "Github",
      url: "https://github.com/melvin-chen",
      icon: require("../../../static/Homepage_Social/github-logo.svg"),
    },
    {
      name: "Resume",
      url: require("../../../static/Homepage_Social/melvin-chen-resume.pdf"),
      icon: require("../../../static/Homepage_Social/text-document.svg"),
    },
  ]
  return (
    <HeroContainer>
      <HeroTitle>Melvin Chen</HeroTitle>
      <HeroDescription>Designer &amp; Developer</HeroDescription>
      <HeroSocialContainer>
        {social.map((item, index) => {
          return (
            <HeroSocialItem key={index}>
              <HeroSocialLinkWrapper href={item.url}>
                <HeroSocialItemLogo src={item.icon} alt={item.name} />
                {item.name}
              </HeroSocialLinkWrapper>
            </HeroSocialItem>
          )
        })}
      </HeroSocialContainer>
    </HeroContainer>
  )
}

export default Hero
