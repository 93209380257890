import styled from "styled-components"
import { device } from "../../../static/sizes/Sizes"

export const BottomProjectsContainer = styled.div`
  margin: 0 60px 40px;
`
export const BottomProjectsTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
`

export const BottomProjectImagesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  opacity: 0.7;
  filter: greyscale(100%);
  -webkit-filter: grayscale(100%);
  flex-wrap: wrap;
  width: 100%;
`

export const BottomProject = styled.img`
  align-self: center;
  max-width: 20%;

  @media ${device.tablet} {
      margin-bottom: 20px;
      max-width: 45%;
  }
`
